<template>
    <div>
        <main-layout></main-layout>
        <v-container v-if="this.authDataGet.is_staff" class="wrapper">
            <v-btn class="control-button" @click="onClickQR">
                <div class="py-2 w-100">
                    <v-icon color="primary" class="w-100">mdi-qrcode-plus</v-icon>
                </div>
                <div class="title">
                    {{ $t("button.generateQR") }}
                </div>
            </v-btn>
        </v-container>
    </div>
</template>

<script>
import MainLayout from "@/layout/MainLayout.vue";
import screen from "@/mixins/screen.js";
import { mapGetters } from "vuex";

export default {
    name: "MarketingPage",
    components: {
        MainLayout,
    },
    mixins: [screen],
    computed: {
        ...mapGetters({
            authDataGet: "auth/authDataGet"
            // authToken: "auth/authToken",
        }),
    },
    methods: {
        onClickQR(){
            this.$router.push("/create-qr");
        }
    }
};
</script>
